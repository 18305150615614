import React from "react"
import styled from "styled-components"

const StyledSecondaryHeading = styled.h2`
  font-size: 1.5rem;
  margin: 0;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.lg}em) {
    font-size: 2rem;
    line-height: 1.2;
  }
`

const SecondaryHeading = ({children, ...props}) => (
  <StyledSecondaryHeading {...props}>
    {children}
  </StyledSecondaryHeading>
)

export default SecondaryHeading

import React from "react"
import styled from "styled-components"

const StyledPrimaryHeading = styled.h1`
  font-size: 1.75rem;
  margin: 0;
  position: relative;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.lg}em) {
    font-size: 2.5rem;
    line-height: 1.35;
  }
`

const PrimaryHeading = ({ children, ...props }) => (
  <StyledPrimaryHeading {...props}>{children}</StyledPrimaryHeading>
)

export default PrimaryHeading

const siteNavLinks = [
  {
    title: "Services",
    url: "/services",
  },
  // {
  //   title: 'About',
  //   url: '/about',
  // },
  {
    title: "Testimonials",
    url: "/testimonials",
  },
  {
    title: "Contact",
    url: "/contact",
  },
  {
    title: "Get an Esimate",
    url: "/estimate",
  },
]

export default siteNavLinks

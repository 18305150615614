import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import { Grid, Row, Col } from "react-styled-flexboxgrid"

import EstimateForm from "../components/EstimateForm"
import { BodyText, PrimaryHeading } from "../components/typography"
import { Layout, Section } from "../components/layout"
import Seo from "../components/Seo"
import siteTheme from "../constants/siteTheme"
import heroBackgroundImage from "../images/compressed/window-cleaning-edmonton-hero.jpg"
import Hero from "../components/Hero"
import ExternalLink from "../components/ExternalLink"

const EstimatePage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          phone
          phoneHref
        }
      }
    }
  `)
  return (
    <ThemeProvider theme={siteTheme}>
      <Layout>
        <Seo
          title="Dr. Squeegee Window Cleaning: Get a Free Estimate"
          description="Get a free, written estimate for window, chandelier, gutter, or pressure cleaning, anywhere in the greater Edmonton area."
        />
        <Hero imageUrl={heroBackgroundImage}>
          <Grid>
            <PrimaryHeading className="centered">
              Get a Free Estimate
            </PrimaryHeading>
          </Grid>
        </Hero>
        <Grid>
          <Section>
            <Row>
              <Col xs={12} sm={8} md={3}>
                <BodyText>
                  Submit this form to get a free written estimate. You may also
                  call us at{" "}
                  <ExternalLink href={data.site.siteMetadata.phoneHref}>
                    {data.site.siteMetadata.phone}
                  </ExternalLink>
                  .
                </BodyText>
                <BodyText>
                  We won’t publish your personal information, or use it in any
                  way other than to provide the requested services.
                </BodyText>
                <BodyText>Thank you for your business!</BodyText>
              </Col>
              <Col xs={12} md={5} mdOffset={1}>
                <EstimateForm />
              </Col>
            </Row>
          </Section>
        </Grid>
      </Layout>
    </ThemeProvider>
  )
}

export default EstimatePage

import React from "react"
import styled from "styled-components"

const StyledExternalLink = styled.a`
  color: ${props => props.theme.colors.secondary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const ExternalLink = ({ children, className, ...props }) => (
  <StyledExternalLink className={className} {...props}>
    {children}
  </StyledExternalLink>
)

export default ExternalLink

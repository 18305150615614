import React from "react"
import styled from "styled-components"

const StyledSubheading = styled.p`
  color: ${props => props.color || props.theme.colors.gray};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.lg}em) {
    margin-top: 1rem;
  }
`

const Subheading = ({ color, children }) => (
  <StyledSubheading color={color}>{children}</StyledSubheading>
)

export default Subheading

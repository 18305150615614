import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import Select from "react-select"
import InputMask from "react-input-mask"
import ClipLoader from "react-spinners/ClipLoader"
import superagent from "superagent"
import { Row, Col } from "react-styled-flexboxgrid"

import Alert from "../components/Alert"
import InputField from "../components/InputField"
import TextArea from "../components/TextArea"
import Button from "../components/Button"
import siteTheme from "../constants/siteTheme"
import ExternalLink from "../components/ExternalLink"

const servicesOptions = [
  { value: "windowsInsideAndOut", label: "Window cleaning inside and outside" },
  { value: "windowsOutsideOnly", label: "Window cleaning exterior only" },
  { value: "chandeliers", label: "Chandelier cleaning" },
  { value: "gutters", label: "Clean gutters and downspouts" },
  { value: "housewash", label: "Housewash" },
  {
    value: "pressureCleaning",
    label: "Pressure cleaning (driveways, walkways, etc.)",
  },
  { value: "skylights", label: "Clean skylights" },
]

const referralOptions = [
  { value: "google", label: "Google" },
  { value: "truck", label: "Dr. Squeegee truck" },
  { value: "personal", label: "Personal" },
]

const postalCodeRegex = /[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]/
const postalCodeMask = "a9a 9a9"
const phoneRegex = /\([0-9][0-9][0-9]\) [0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/
const phoneMask = "(999) 999-9999"

const schema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  streetAddress: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  postalCode: Yup.string()
    .matches(postalCodeRegex, "Invalid postal code")
    .required("Required"),
  phone: Yup.string()
    .matches(phoneRegex, "Invalid phone number")
    .required("Required"),
  email: Yup.string().email("Invalid email"),
  services: Yup.array()
    .min(1, "Please select at least one service")
    .nullable()
    .required("Please select at least one service"),
})

const MaskedField = ({ field, form, mask, ...props }) => (
  <InputMask
    mask={mask}
    name="postalCode"
    onChange={e => {
      form.setFieldValue("postalCode")
    }}
    {...field}
    {...props}
  />
)

const StyledErrorMessage = styled.div`
  font-size: 0.875rem;
  color: #cc0000;
  margin-top: 0.25rem;
`

const ErrorMessage = ({ error, touched }) => (
  <StyledErrorMessage>{error && touched ? error : null}</StyledErrorMessage>
)

const HtmlButton = ({ children, isLightBackground, ...props }) => (
  <button {...props}>{children}</button>
)

const FieldSelect = ({
  options,
  placeholder,
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { error, touched, disabled } = props
  return (
    <Select
      options={options}
      onChange={selectedServices => {
        form.setFieldValue(field.name, selectedServices)
      }}
      placeholder={placeholder}
      name={field.name}
      onBlur={() => {
        form.setFieldTouched(field.name, true)
      }}
      isDisabled={disabled}
      {...props}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: siteTheme.colors.backgroundGray,
          primary: siteTheme.colors.primary,
        },
      })}
      styles={{
        control: provided => ({
          ...provided,
          marginTop: `1rem`,
          minHeight: `3rem`,
          paddingLeft: `1rem`,
          paddingTop: `0.25rem`,
          paddingBottom: `0.25rem`,
          borderColor: touched && error ? `#CC0000` : siteTheme.colors.gray,
          backgroundColor: `white`,
          color: disabled ? siteTheme.colors.lightGray : provided.color,
        }),
        valueContainer: provided => ({
          ...provided,
          padding: `0`,
        }),
        placeholder: provided => ({
          ...provided,
          width: `100%`,
          marginLeft: `0`,
          marginRight: `0`,
          color: siteTheme.colors.gray,
        }),
        multiValue: provided => ({
          ...provided,
          paddingLeft: `1rem`,
        }),
        multiValueLabel: provided => ({
          ...provided,
          color: disabled ? siteTheme.colors.lightGray : siteTheme.colors.gray,
          backgroundColor: disabled
            ? siteTheme.colors.backgroundGray
            : provided.backgroundColor,
        }),
        multiValueRemove: provided => ({
          ...provided,
          color: disabled ? siteTheme.colors.lightGray : siteTheme.colors.gray,
          backgroundColor: disabled
            ? siteTheme.colors.backgroundGray
            : provided.backgroundColor,
        }),
        singleValue: provided => ({
          ...provided,
          color: disabled ? siteTheme.colors.lightGray : siteTheme.colors.gray,
        }),
      }}
      {...props}
    />
  )
}

const submitUrl =
  "https://5lmtlwagm4.execute-api.us-east-1.amazonaws.com/Prod/estimate"

const onSubmit = (values, actions) => {
  superagent
    .post(submitUrl)
    .send({
      ...values,
      services: values.services.map(s => s.label),
      referral: values.referral ? values.referral.label : null,
    })
    .then(() => {
      actions.setStatus("success")
    })
    .catch(() => {
      actions.setStatus("error")
      actions.setSubmitting(false)
    })
}

const EstimateForm = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          phone
          phoneHref
        }
      }
    }
  `)
  return (
    <Formik
      validationSchema={schema}
      validateOnChange={false}
      initialValues={{
        firstName: "",
        lastName: "",
        streetAddress: "",
        city: "",
        postalCode: "",
        phone: "",
        email: "",
        comments: "",
        services: [],
        referral: null,
      }}
      onSubmit={onSubmit}
    >
      {({
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        status,
        isValid,
        submitCount,
      }) => (
        <Form onSubmit={handleSubmit}>
          {status !== "success" && (
            <>
              <Row>
                <Col xs={12} sm={6}>
                  <InputField
                    name="firstName"
                    placeholder="First Name*"
                    touched={touched.firstName}
                    error={errors.firstName}
                    disabled={isSubmitting}
                    firstRow
                    aria-label="First Name"
                    aria-required="true"
                    aria-invalid={!!(touched.firstName && errors.firstName)}
                  />
                  <ErrorMessage
                    error={errors.firstName}
                    touched={touched.firstName}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <InputField
                    name="lastName"
                    placeholder="Last Name*"
                    touched={touched.lastName}
                    error={errors.lastName}
                    disabled={isSubmitting}
                    firstRow
                    aria-label="Last Name"
                    aria-required="true"
                    aria-invalid={!!(touched.lastName && errors.lastName)}
                  />
                  <ErrorMessage
                    error={errors.lastName}
                    touched={touched.lastName}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <InputField
                    name="streetAddress"
                    placeholder="Street Address*"
                    touched={touched.streetAddress}
                    error={errors.streetAddress}
                    disabled={isSubmitting}
                    aria-label="Street Address"
                    aria-required="true"
                    aria-invalid={
                      !!(touched.streetAddress && errors.streetAddress)
                    }
                  />
                  <ErrorMessage
                    error={errors.streetAddress}
                    touched={touched.streetAddress}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <InputField
                    name="city"
                    placeholder="City / Town*"
                    touched={touched.city}
                    error={errors.city}
                    disabled={isSubmitting}
                    aria-label="City / Town"
                    aria-required="true"
                    aria-invalid={!!(touched.city && errors.city)}
                  />
                  <ErrorMessage error={errors.city} touched={touched.city} />
                </Col>
                <Col xs={12} sm={6}>
                  <InputField
                    name="postalCode"
                    placeholder="Postal Code*"
                    mask={postalCodeMask}
                    component={MaskedField}
                    touched={touched.postalCode}
                    error={errors.postalCode}
                    disabled={isSubmitting}
                    aria-label="Postal Code"
                    aria-required="true"
                    aria-invalid={!!(touched.postalCode && errors.postalCode)}
                  />
                  <ErrorMessage
                    error={errors.postalCode}
                    touched={touched.postalCode}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <InputField
                    name="phone"
                    type="tel"
                    placeholder="Phone*"
                    mask={phoneMask}
                    component={MaskedField}
                    touched={touched.phone}
                    error={errors.phone}
                    disabled={isSubmitting}
                    aria-label="Phone"
                    aria-required="true"
                    aria-invalid={!!(touched.phone && errors.phone)}
                  />
                  <ErrorMessage error={errors.phone} touched={touched.phone} />
                </Col>
                <Col xs={12} sm={6}>
                  <InputField
                    name="email"
                    type="email"
                    placeholder="Email"
                    touched={touched.email}
                    error={errors.email}
                    disabled={isSubmitting}
                    aria-label="Email"
                    aria-required="false"
                    aria-invalid={!!(touched.email && errors.email)}
                  />
                  <ErrorMessage error={errors.email} touched={touched.email} />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Field
                    component={FieldSelect}
                    name="services"
                    options={servicesOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    touched={touched.services}
                    error={errors.services}
                    disabled={isSubmitting}
                    placeholder="What services would you like?*"
                    aria-label="What services would you like?"
                    aria-required="true"
                    aria-invalid={!!(touched.services && errors.services)}
                  />
                  <ErrorMessage
                    error={errors.services}
                    touched={touched.services}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Field
                    component={FieldSelect}
                    name="referral"
                    options={referralOptions}
                    disabled={isSubmitting}
                    placeholder="How did you hear about us?"
                    aria-label="How did you hear about us?"
                    aria-required="false"
                    aria-invalid={!!(touched.referral && errors.referral)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextArea
                    component="textarea"
                    name="comments"
                    placeholder="Anything else we should know?"
                    disabled={isSubmitting}
                    aria-label="Anything else we should know?"
                    aria-required="false"
                    aria-invalid="false"
                  />
                </Col>
              </Row>

              <Button
                as={HtmlButton}
                type="submit"
                isLightBackground
                disabled={isSubmitting}
                aria-label="Submit"
              >
                {isSubmitting ? (
                  <ClipLoader sizeUnit="rem" size={2} color="white" loading />
                ) : (
                  "Submit"
                )}
              </Button>
            </>
          )}
          {submitCount > 0 && !isValid && (
            <Alert variant="error">
              There are errors with the form above. Please fix the errors and
              try submitting again.
            </Alert>
          )}
          {status === "success" && (
            <Alert variant="success">
              Thanks for your request! We'll contact you shortly. Please contact
              us at{" "}
              <ExternalLink href={data.site.siteMetadata.phoneHref}>
                {data.site.siteMetadata.phone}
              </ExternalLink>{" "}
              if you have any questions.
            </Alert>
          )}
          {status === "error" && (
            <Alert variant="error">
              Something went wrong submitting your request. Please try again or
              call us at{" "}
              <ExternalLink href={data.site.siteMetadata.phoneHref}>
                {data.site.siteMetadata.phone}
              </ExternalLink>
              .
            </Alert>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default EstimateForm

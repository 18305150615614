import React from "react"
import styled from "styled-components"

const StyledHero = styled.div`
  color: white;
  padding-top: 2rem;
  padding-bottom: 2.5rem;
  background-color: ${props => props.theme.colors.primary};
  box-shadow: inset 0 -0.625rem 0.625rem rgba(0, 0, 0, 0.1);
  background-image: url(${props => props.imageUrl});
  background-size: cover;
  background-position: 50% 10%;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.lg}em) {
    padding-top: 3rem;
    padding-bottom: 3.5rem;
  }
`

const Hero = ({ imageUrl, children }) => (
  <StyledHero imageUrl={imageUrl}>{children}</StyledHero>
)

export default Hero

import React from "react"
import styled from "styled-components"

import { StyledTertiaryHeading } from "./TertiaryHeading"

const StyledBodyText = styled.p`
  margin: 1rem 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${StyledTertiaryHeading} + & {
    margin-top: 0.25rem;
  }
`

const BodyText = ({ children, ...props }) => (
  <StyledBodyText {...props}>{children}</StyledBodyText>
)

export default BodyText

import BodyText from "./BodyText"
import PrimaryHeading from "./PrimaryHeading"
import SecondaryHeading from "./SecondaryHeading"
import TertiaryHeading from "./TertiaryHeading"
import Subheading from "./Subheading"

export {
  BodyText,
  PrimaryHeading,
  SecondaryHeading,
  TertiaryHeading,
  Subheading,
}

import React from "react"
import styled from "styled-components"

const StyledSection = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.lg}em) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`

const Section = ({ children, ...props }) => (
  <StyledSection {...props}>{children}</StyledSection>
)

export default Section

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Grid, Row, Col } from "react-styled-flexboxgrid"

import Section from "./layout/Section"
import FacebookIcon from "./FacebookIcon"
import Link from "./Link"
import ExternalLink from "./ExternalLink"
import siteNavLinks from "../constants/siteNavLinks"

const Phone = styled(ExternalLink)`
  display: inline-block;
  font-weight: bold;
  margin-top: 0.75rem;
  color: ${props => props.theme.colors.textBlack};
`

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const NavLink = styled(Link)`
  color: ${props => props.theme.colors.textBlack};
  margin-top: 0.75rem;
  text-align: right;
  display: inline-flex;
`

const FollowUs = styled.div`
  margin-top: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 0.75rem;
  font-weight: bold;
  color: ${props => props.theme.colors.gray};
`

const StyledFooter = styled.footer`
  border-top: 1px solid ${props => props.theme.colors.lightGray};
`

const Copyright = styled.div`
  margin-top: 1rem;
  color: ${props => props.theme.colors.gray};
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          phoneHref
          phone
          facebookUrl
          email
        }
      }
    }
  `)
  return (
    <StyledFooter role="contentinfo">
      <Grid>
        <Section>
          <Row>
            <Col xs={8}>
              <div>{data.site.siteMetadata.title}</div>
              <Phone href={data.site.siteMetadata.phoneHref}>
                {data.site.siteMetadata.phone}
              </Phone>
              <FollowUs>Follow Us</FollowUs>
              <a href={data.site.siteMetadata.facebookUrl}>
                <FacebookIcon />
              </a>
            </Col>
            <Col xs={4}>
              <Nav aria-label="Site links">
                {siteNavLinks.map(link => (
                  <NavLink to={link.url} key={link.url}>
                    {link.title}
                  </NavLink>
                ))}
              </Nav>
            </Col>
          </Row>
          <Copyright>
            © {data.site.siteMetadata.title}, {new Date().getFullYear()}
          </Copyright>
        </Section>
      </Grid>
    </StyledFooter>
  )
}

export default Footer

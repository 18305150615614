const theme = {
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 1.25, // rem
    outerMargin: 1, // rem
    mediaQuery: "only screen",
    container: {
      sm: 46, // rem
      md: 61, // rem
      lg: 76, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
      lg: 75, // em
    },
  },
  colors: {
    primary: `#5460F3`,
    secondary: `#3d73da`,
    gray: `#666`,
    lightGray: `#DDDDDD`,
    backgroundGray: `#f4f4f7`,
    textBlack: `#222`,
  },
  header: {
    height: {
      sm: `3rem`,
      lg: `4rem`,
      desktop: `5rem`,
    },
  },
  grid: {
    gutter: `10px`,
  },
  z: {
    header: 2000,
  },
}

export default theme

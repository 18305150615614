import React from "react"
import styled from "styled-components"
import { Field } from "formik"

const StyledInputField = styled(
  ({ firstRow, disabled, touched, error, ...rest }) => <Field {...rest} />
)`
  appearance: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 3rem;
  line-height: 1.5;
  padding: 0 1rem;
  margin-top: 1rem;
  color: ${props =>
    props.disabled
      ? props.theme.colors.lightGray
      : props.theme.colors.textBlack};
  border: 1px solid
    ${props =>
      props.touched && props.error ? `#CC0000` : props.theme.colors.gray};
  outline: 0;
  border-radius: 0;

  &:focus {
    border-color: ${props => props.theme.colors.primary};
  }

  &::placeholder {
    color: ${props =>
      props.disabled
        ? props.theme.colors.lightGray
        : props.theme.colors.gray} !important;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    margin-top: ${props => (props.firstRow ? 0 : "1rem")};
  }
`

const InputField = props => <StyledInputField {...props} />

export default InputField

import React from "react"
import styled from "styled-components"

const StyledLogo = styled.svg`
  height: 1.875rem;
`

const Logo = () => (
  <StyledLogo
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit="1.5"
    clipRule="evenodd"
    viewBox="35 59 1430 264"
  >
    <path
      fill="none"
      stroke="#fff"
      strokeWidth="6.66"
      d="M134 475V336s26.272-2.027 56 0c26.311 1.794 63 12.621 63 68s-46.856 71-68.801 71h-7.2v-39S217 437.438 217 401s-50-31-50-31v105h-33z"
      transform="translate(-131.56 -331.437) scale(1.25044) translate(2)"
    />
    <path
      fill="none"
      stroke="#fff"
      strokeWidth="6.66"
      d="M270 475s-.05-43.557 0-56c.217-54.457 48-49 48-49v33s-17.272 1.302-17 29c.272 27.698 0 43 0 43h-31z"
      transform="translate(-131.56 -331.437) scale(1.25044) translate(2 -1)"
    />
    <path
      fill="none"
      stroke="#fff"
      strokeWidth="6.66"
      d="M428 442h15c6.871 0 15.396-5.408 15-16-.651-17.438-23.109-22.339-23-53 .127-35.763 32.535-40 49.197-40H496v33s-23.18-.813-23 17.999c.217 22.699 20.891 26.399 21 53.001.136 33.333-22.902 40-46 40h-20v-35zM573.601 442H561c-7.269 0-16.401-5.556-16.401-18 0-9.997 9.179-19 18.401-19 13.761 0 20 10.115 20 21v88h32v-87c0-12.88.606-55-51-55-41.57 0-51 33.148-51 51 0 21.675 10.407 52 46 52h14.601v-33z"
      transform="translate(-131.56 -331.437) scale(1.25044) translate(2)"
    />
    <path
      fill="none"
      stroke="#fff"
      strokeWidth="6.66"
      d="M668 372v44c0 12.965-.07 27 11 27s11-12.636 11-27v-44h33v48c0 14.236 2.997 55-44 55s-44-42.493-44-53.576V372h33z"
      transform="translate(-131.56 -331.437) scale(1.25044) translate(2 1.6)"
    />
    <path
      fill="none"
      stroke="#fff"
      strokeWidth="6.66"
      d="M794 372c-25.596 0-51.5 16.196-51.5 51.5S765.713 475 794 475s40-14 40-14l-15-23c-10.479 8.318-18.529 7.886-25 2l42-42s-9.713-26-42-26z"
      transform="translate(-131.56 -331.437) scale(1.25044) translate(2)"
    />
    <path
      fill="none"
      stroke="#fff"
      strokeWidth="6.66"
      d="M777 431s-9.844-12.296 0-24c10.786-12.824 25-2 25-2l-25 26z"
      transform="translate(-131.56 -331.437) scale(1.25044) translate(3 -2)"
    />
    <path
      fill="none"
      stroke="#fff"
      strokeWidth="6.66"
      d="M794 372c-25.596 0-51.5 16.196-51.5 51.5S765.713 475 794 475s40-14 40-14l-15-23c-10.479 8.318-18.529 7.886-25 2l42-42s-9.713-26-42-26z"
      transform="translate(-131.56 -331.437) scale(1.25044) translate(108)"
    />
    <path
      fill="none"
      stroke="#fff"
      strokeWidth="6.66"
      d="M777 431s-9.844-12.296 0-24c10.786-12.824 25-2 25-2l-25 26z"
      transform="translate(-131.56 -331.437) scale(1.25044) translate(109 -2)"
    />
    <path
      fill="none"
      stroke="#fff"
      strokeWidth="6.66"
      d="M1006 372c-30.43 0-52 22.876-52 48 0 21.182 14.56 47 47 47h11.4v-27s-26.4 1.885-26.4-16.5c0-12.769 6.301-20.5 18.5-20.5 7.06 0 17.5 3.892 17.5 22v32c0 13.707-1 28-27 28h-17v32h21c23.13 0 54-8.67 54-65 0-21.061-.44-27.639-.44-34 0-13.147-5.04-46-46.56-46z"
      transform="translate(-131.56 -331.437) scale(1.25044) translate(2)"
    />
    <path
      fill="none"
      stroke="#fff"
      strokeWidth="6.66"
      d="M794 372c-25.596 0-51.5 16.196-51.5 51.5S765.713 475 794 475s40-14 40-14l-15-23c-10.479 8.318-18.529 7.886-25 2l42-42s-9.713-26-42-26z"
      transform="translate(-131.56 -331.437) scale(1.25044) translate(332)"
    />
    <path
      fill="none"
      stroke="#fff"
      strokeWidth="6.66"
      d="M777 431s-9.844-12.296 0-24c10.786-12.824 25-2 25-2l-25 26z"
      transform="translate(-131.56 -331.437) scale(1.25044) translate(333 -2)"
    />
    <path
      fill="none"
      stroke="#fff"
      strokeWidth="6.66"
      d="M794 372c-25.596 0-51.5 16.196-51.5 51.5S765.713 475 794 475s40-14 40-14l-15-23c-10.479 8.318-18.529 7.886-25 2l42-42s-9.713-26-42-26z"
      transform="translate(-131.56 -331.437) scale(1.25044) translate(438)"
    />
    <path
      fill="none"
      stroke="#fff"
      strokeWidth="6.66"
      d="M777 431s-9.844-12.296 0-24c10.786-12.824 25-2 25-2l-25 26z"
      transform="translate(-131.56 -331.437) scale(1.25044) translate(439 -2)"
    />
    <circle
      cx="353"
      cy="456"
      r="19"
      fill="none"
      stroke="#fff"
      strokeWidth="6.66"
      transform="translate(-131.56 -331.437) scale(1.25044) translate(1)"
    />
  </StyledLogo>
)

export default Logo

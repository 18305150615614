import React from "react"
import styled from "styled-components"

const StyledTertiaryHeading = styled.h3`
  font-size: 1rem;
  margin: 0;
  margin-top: 1.5rem;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  line-height: 1.7;
  letter-spacing: 0.3px;
  line-height: 1.2;

  &:first-child {
    margin-top: 0;
  }
`

const TertiaryHeading = ({ children, ...props }) => (
  <StyledTertiaryHeading {...props}>{children}</StyledTertiaryHeading>
)

export { StyledTertiaryHeading }

export default TertiaryHeading

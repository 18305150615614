import React from "react"
import styled from "styled-components"
import { transparentize } from "polished"

const getColor = variant => {
  switch (variant) {
    case "success":
      return "green"
    case "error":
      return "red"
    default:
      return "gray"
  }
}

const StyledAlert = styled.div`
  min-height: 3rem;
  padding: 0.75rem 1rem;
  color: ${props => getColor(props.variant)};
  border: 1px solid ${props => getColor(props.variant)};
  background-color: ${props => transparentize(0.9, getColor(props.variant))};
  margin-top: 1rem;
`

const Alert = ({ children, ...props }) => (
  <StyledAlert {...props} role="alert">
    {children}
  </StyledAlert>
)

export default Alert

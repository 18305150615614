import { Link } from "gatsby"
import { Grid } from "react-styled-flexboxgrid"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"
import Menu from "@material-ui/icons/Menu"
import Close from "@material-ui/icons/Close"
import Logo from "./logo"
import siteNavLinks from "../constants/siteNavLinks"

const StyledHeader = styled.header`
  background: ${props => props.theme.colors.primary};
  height: ${props => props.theme.header.height.sm};
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  z-index: ${props => props.theme.z.header};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.lg}em) {
    height: ${props => props.theme.header.height.lg};
  }
`
const StyledLink = styled(Link)`
  display: flex;
`

const StyledMenu = styled(Menu)`
  fill: white !important;
`

const StyledClose = styled(Close)`
  fill: white !important;
`

const StyledGrid = styled(Grid)`
  height: 100%;
`

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Nav = styled.nav`
  display: none;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    display: block;
  }
`

const NavLink = styled(Link)`
  font-size: 1.25rem;
  margin-top: 1rem;
  color: white;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    font-size: 1rem;

    & + & {
      margin-left: 2rem;
    }
  }
`

const StyledMobileNav = styled.nav`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.primary};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  padding-top: ${props => props.theme.header.height.sm};
  position: absolute;
  top: 0;
  left: 100%;
  transition: transform 0.2s ease;
  transform: ${({ open }) => (open ? "translateX(-100%)" : "translateX(0)")};
  z-index: 1;
  width: 75vw;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    width: 35vw;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    display: none;
  }
`

const MobileNav = ({ open, children }) => (
  <StyledMobileNav open={open}>{children}</StyledMobileNav>
)

const StyledHambugerButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  z-index: 2;
  width: ${props => props.theme.header.height.sm};
  height: ${props => props.theme.header.height.sm};
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(
    ${props => `${props.theme.flexboxgrid.outerMargin}rem`}
  );
  outline: 0;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    display: none;
  }
`

const HamburgerButton = ({ open, setOpen }) => (
  <StyledHambugerButton
    open={open}
    onClick={() => {
      setOpen(!open)
    }}
    aria-label={open ? "Close navigation menu" : "Open navigation menu"}
  >
    {!open && <StyledMenu />}
    {open && <StyledClose />}
  </StyledHambugerButton>
)

const StyledBackdrop = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  opacity: ${props => (props.open ? 1 : 0)};
  height: ${props => (props.open ? "100%" : 0)};
  background: rgba(0, 0, 0, 0.85);
  transition: opacity 0.2s ease;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    display: none;
  }
`

const Backdrop = ({ open, setOpen }) => (
  <StyledBackdrop open={open} onClick={() => setOpen(false)} />
)

const Header = ({ siteTitle }) => {
  const [open, setOpen] = useState(false)
  return (
    <StyledHeader>
      <StyledGrid>
        <HeaderContent>
          <StyledLink to="/" aria-label="Dr. Squeegee Window Cleaning">
            <Logo />
          </StyledLink>
          <Nav>
            {siteNavLinks.map(link => (
              <NavLink to={link.url} key={link.url}>
                {link.title}
              </NavLink>
            ))}
          </Nav>
          <HamburgerButton open={open} setOpen={setOpen} />
          <MobileNav open={open}>
            {siteNavLinks.map(link => (
              <NavLink
                to={link.url}
                key={link.url}
                onClick={() => setOpen(false)}
              >
                {link.title}
              </NavLink>
            ))}
          </MobileNav>
          <Backdrop open={open} setOpen={setOpen} />
        </HeaderContent>
      </StyledGrid>
    </StyledHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

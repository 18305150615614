import React from "react"
import styled from "styled-components"

const StyledContainer = styled.div`
  padding-left: ${props => props.theme.grid.gutter};
  padding-right: ${props => props.theme.grid.gutter};
`

const Container = ({children, ...props}) => (
  <StyledContainer {...props}>
    {children}
  </StyledContainer>
)

export default Container

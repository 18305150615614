import React from "react"
import styled from "styled-components"
import {Link as GastbyLink} from "gatsby"

const StyledLink = styled(GastbyLink)`
  color: ${props => props.theme.colors.secondary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const Link = ({children, className, ...props}) => (
  <StyledLink className={className} {...props}>
    {children}
  </StyledLink>
)

export default Link

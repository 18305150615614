import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "normalize.css"
import "./layout.scss"
import Header from "../Header"
import Footer from "../Footer"
import styled from "styled-components"

const Main = styled.main`
  margin-top: ${props => props.theme.header.height.sm};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.lg}em) {
    margin-top: ${props => props.theme.header.height.lg};
  }
`

const StyledLayout = styled.div`
  color: ${props => props.theme.colors.textBlack};
`

const windowLoadedClassName = "window-loaded"

const flagWindowLoaded = () => {
  document.body.classList.add(windowLoadedClassName)
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    if (document.readyState === "complete") {
      flagWindowLoaded()
    } else {
      window.addEventListener("load", flagWindowLoaded)
    }
  }, [])

  return (
    <StyledLayout>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Main>{children}</Main>
      <Footer />
    </StyledLayout>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

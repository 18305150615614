import React from "react"
import styled from "styled-components"
import {Grid} from "react-styled-flexboxgrid"

const StyledGridWithBackground = styled.div`
  background-color: ${props => props.theme.colors.primary};
  background-size: cover;
  background-position: center center;
  color: white;

  .window-loaded & {
    background-image: url(${props => props.imageUrl});
  }
`

const GridWithBackground = ({imageUrl, children}) => (
  <StyledGridWithBackground imageUrl={imageUrl}>
    <Grid>
      {children}
    </Grid>
  </StyledGridWithBackground>
)

export default GridWithBackground

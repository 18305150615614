import React from "react"
import styled from "styled-components"
import InputField from "./InputField"

const StyledTextArea = styled(InputField)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  min-height: 8rem;
  max-width: 100%;
  vertical-align: top;
  line-height: inherit;
`

const TextArea = ({ field, ...props }) => <StyledTextArea {...props} />

export default TextArea
